import { FunctionComponent, useEffect, useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import clsx from "clsx"
import Image from "next/legacy/image"
import Link from "next/link"
import S from "./product-box.module.scss"
import CartIcon from "../svgs/cart.svg"
import API from "@/lib/api"
import {
  getProductDefaultQuantity,
  getProductDefaultQuantityUnit,
  getProductUrl,
} from "@/lib/product"
import { event } from "@/lib/tracking"
import useUser from "@/lib/useUser"
import { Product } from "@/types"

type ProductBoxProps = {
  data: Product
  className?: string
}

const ProductBox: FunctionComponent<ProductBoxProps> = ({
  data,
  className,
}) => {
  const { user } = useUser()
  const queryClient = useQueryClient()
  const [added, setAdded] = useState(false)

  useEffect(() => {
    if (added) {
      setTimeout(() => {
        setAdded(false)
      }, 2000)
    }
  }, [added])

  const cart = useMutation({
    mutationFn: async () => {
      await API.services.cart.add(user?.access_token!, {
        product_id: data.id,
        quantity: getProductDefaultQuantity(data),
        quantity_unit: getProductDefaultQuantityUnit(data),
      })

      event({
        category: "ecommerce",
        name: "add-to-cart",
        action: "add",
        customFields: {
          product: data,
        },
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["cart"] })
      setAdded(true)
    },
  })

  return (
    <div className={clsx(S.box, className)}>
      <Link href={getProductUrl(data)} className="stretched-link">
        <Image
          src={data.image_url || "/images/placeholder-image-box.svg"}
          alt={data.product_title}
          width={270}
          height={220}
          layout="responsive"
          quality="100"
        />
      </Link>
      <div className={S.inner}>
        <p className={S.name}>{data.product_title}</p>
      </div>
      <div className={S.meta}>
        <div className="row align-items-center">
          <div className="col">
            <p className={S.code}>{data.item_number}</p>
          </div>
          {user && user.isLoggedIn ? (
            <div className="col-auto">
              <button
                type="button"
                onClick={() => cart.mutate()}
                className={clsx(
                  S.cart,
                  { "text-success": added },
                  "d-flex align-items-center",
                )}
              >
                <CartIcon className={S.cartIcon} />
                <p className={S.cartText}>{added ? "Added" : "Add to Cart"}</p>
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default ProductBox
